import React from "react";
import "../styles/page.css";

import Slideshow1 from "../assets/Slideshows_1.png";

import Slideshow3 from "../assets/Slideshows_3.png";
import Slideshow4 from "../assets/Slideshows_4.png";
import Slideshow5 from "../assets/Slideshows_5.png";

function Photo() {
  return (
    <div className="page">
      <div className="photo">
        <div className="photoIntro">
          I picked up digital camera photography as a hobby last year, but I have always enjoyed
          taking photos of people, places, and moments I find interesting.
          <br></br>
          <br></br>
          I shoot primarily on a Canon EOS 60D.
          <br></br>
          Here are some of my favorite shots so far:
        </div>
        {/* <img src={Slideshow2} className="photoSlide" alt="Slideshow of various photos" /> */}
        <img src={Slideshow1} className="photoSlide" alt="Slideshow of various photos" />
        <img src={Slideshow3} className="photoSlide" alt="Slideshow of various photos" />
        <img src={Slideshow4} className="photoSlide" alt="Slideshow of various photos" />
        <img src={Slideshow5} className="photoSlide" alt="Slideshow of various photos" />
        <div className="photoIntro">
          I'm always looking for new opportunities and new subjects!
        </div>
        <div className="spacer">
          <br></br>
          <br></br>
        </div>
      </div>
    </div>
  );
}

export default Photo;