import './App.css';
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/navbar";
import Footer from "./components/footer";

import Photo from "./pages/Photo";
import Music from './pages/Music';
import Home from './pages/Home';
import Code from './pages/Code';

function App() {
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    // https://www.kindacode.com/article/ways-to-set-page-title-dynamically-in-react/
    document.title = "ricepatch";
  });

  return (
    <div className="App">
      <title>ricepatch</title>
      <Router>
        <Navbar />
        <Footer />
        <Routes>
          <Route path="/photo" exact element={<Photo />} />
          <Route path="/music" exact element={<Music />} />
          <Route path="/home" exact element={<Home />} />
          <Route path="/" exact element={<Home />} />
          <Route path="/code" exact element={<Code />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
