import React from "react";
import "../styles/navbar.css";

import { Link } from "react-router-dom";

function Navbar() {
  return (
    <div className="navbar">
      <p>
        <Link to='/home' className="title">ricepatch</Link><br></br>
        <Link to='/photo'>photo</Link><br></br>
        <Link to='/music'>music</Link><br></br>
        <Link to='/code'>code</Link>
      </p>
    </div>
  )
}

export default Navbar;