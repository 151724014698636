import React from "react";
import "../styles/page.css";

import Acadev from "../assets/Acadev.jfif";
import Berkeley from "../assets/Berkeley.jfif";

function Code() {
  return (
    <div className="page">
      <div className="code">
        <table className="codeTable">
          <tr>
            <td>
              <img src={Berkeley} style={{ float: 'left', width: '33vw' }} alt={"Profile"}></img>
            </td>
            <td>
              <br></br>
                I'm currently an undergraduate studying Data Science and Computer Science at UC Berkeley.
                Some of my particular interests include automation and machine learning applications for data science.
              <br></br>
            </td>
          </tr>
        </table>
        <table className="codeTable">
          <tr>
            <td>
              <br></br>
                I'm also a mentor in the Academic Development committee of the Data Science Society at Berkeley.
                We teach an introductory data science course supported by the university.
              <br></br>
            </td>
            <td>
              <img src={Acadev} style={{ float: 'left', width: '30vw' }} alt={"Profile"}></img>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default Code;