import React from "react";
import "../styles/page.css";

import ProfilePic from "../assets/LinkedIn_pfp.jpg";

function Home() {
  return (
    <div className="page">
      <div className="Home">
        <table className="homeTable">
          <tr>
            <td>
              <img src={ProfilePic} style={{ float: 'left', width: '24vw' }} alt={"Profile"}></img>
            </td>
            <td>
              <br></br>
              Hello, I'm James. Welcome to my personal website!
              <br></br>
              <br></br>
              I am a Data Science and Computer Science undergraduate at UC Berkeley with a passion for music production and photography.
              I believe technology and creativity go hand in hand,
              and I enjoy using my technology skills to enhance my creative output and to understand underlying patterns in music and art.
              <br></br>
              <br></br>
            </td>
          </tr>
        </table>
        <table className="homeTable">
          <tr>
            <td>
              <br></br>
              On this website, you will find my portfolio of coding experience,
              samples of my music, and some of my favorite photography shots.
              <br></br>
              <br></br>
              I hope you enjoy browsing through my work. I am always looking for new opportunities and perspectives,
              so
              <br></br>
              please reach out to me through any of the options on the right sidebar.
              <br></br>
              Thanks for visiting!
              <br></br>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default Home;