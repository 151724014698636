import React from "react";
import "../styles/footer.css";

import Insta from "../assets/instagram_logo_circle.png";
import YouTube from "../assets/youtube_logo_circle.png";
import SoundCloud from "../assets/soundcloud_logo_circle.png";
import TikTok from "../assets/tiktok_logo_circle.png";
import Mail from "../assets/mail_logo_circle.png";

function Footer() {
  return (
    <div className="footer">
      <div className="logos">
        <p>
          <a href='https://www.instagram.com/rice.patch/' target="_blank" rel="noreferrer" class="logo">
            <img src={Insta} alt="Instagram" />
          </a><br></br>
          <a href='https://www.youtube.com/channel/UCXbFp5yDPFIPmymwjlvpoHg' target="_blank" rel="noreferrer" class="logo">
            <img src={YouTube} alt="YouTube" />
          </a><br></br>
          <a href='https://soundcloud.com/ricepatch' target="_blank" rel="noreferrer" class="logo">
            <img src={SoundCloud} alt="SoundCloud" />
          </a><br></br>
          <a href='https://www.tiktok.com/@rice.patch' target="_blank" rel="noreferrer" class="logo">
            <img src={TikTok} alt="TikTok" />
          </a>
          <a href='mailto:jamesyang02@berkeley.edu?' rel="noreferrer" class="logo">
            <img src={Mail} alt="Email" />
          </a>
        </p>
      </div>
    </div>
  )
}

export default Footer;