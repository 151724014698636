import React from "react";
import "../styles/page.css";

function Music() {
  return (
    <div className="page">
      <table className="music">
        <tr>
          <td className="musicIframe">
          <iframe
            title="spotify"
            src="https://open.spotify.com/embed/artist/7d9lvR1o04hPx1CqFdxW74?utm_source=generator"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy">
          </iframe>
          </td>
          <td className="musicText">
            I love music of all kinds! I'm a classically trained pianist and I recently picked up guitar as a hobby.
            <br></br>
            <br></br>
            I've also produced my own music for over 6 years. I primarily produce rap, RnB, and various electronic genres.
            <br></br>
            <br></br>
            Follow me on Spotify, where you'll find my official releases...
          </td>
        </tr>
        <tr>
          <td className="musicIframe">
            <iframe
              title="soundcloud2"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1535275663&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true">
            </iframe>
          </td>
          <td className="musicText">
            or check out some of my latest beats in progress on my SoundCloud WIPs page...
          </td>
        </tr>
        <tr>
          <td className="musicIframe">
          <iframe
            title="soundcloud"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1563839374&color=%2322271e&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true">
          </iframe>
          </td>
          <td className="musicText">
            or find my main SoundCloud here, where I post mashups, remixes, and silly audio memes.
          </td>
        </tr>
      </table>
      <div className="spacer">
        <br></br>
      </div>
    </div>
  );
}

export default Music;